<template>
  <main class="index">
    <section class="manual">
      <div class="h2">
        Новинки и трендовые игры
      </div>

      <div class="collections">
        <div v-for="(col, key, i) in items" :key="`col-${key}`" class="item">
          <div class="h2">
            <nuxt-link :to="`/games?page=0&genre=${genres[key].id}`">
              {{ genres[key].name }}
              <img src="/static_files/icons/arrow.svg" alt="">
              <!-- <nuxt-img
                src="/static_files/icons/arrow.svg"
                alt="Avatar"
                preload
                fit="cover"
                width="14"
                height="14"
                format="svg"
              /> -->
            </nuxt-link>
          </div>
          <Slider v-if="i < 1" :items="items[key]" :preload="true" />
          <Slider v-else :items="items[key]" />
        </div>
      </div>

      <div class="description">
        <h1>Скачивайте последние версии игр через торрент</h1>
        <p>
          На нашем сайте вы можете скачать бесплатно торрент файлы полных и последних версий игр на ПК и консоли.
        </p>
        <p>
          EsdoGames («Эсдо Геймс») – это уникальный открытый каталог игр на компьютер и консоли разных поколений, ассортимент наименований превосходит все ожидания даже самых требовательных геймеров. 
          На фоне других торрент-трекеров уникальность нашего ресурса заключена в ответственном подходе команды к добавлению видеоигр и их подбору по жанрам, годам, платформам, типу геймплея и популярных категорий и тегов.
          На страницах нашего торрент-каталога вы сможете подобрать себе виртуальное развлечение на любой "цвет и вкус".
        </p>
        <p>
          Мы ежедневно пополняем наш каталог и торрент-файлы к играм на ПК и консоли на самые свежие и актуальные их версии, следим за обновлениями, дополняем новые репаки, краткое и полное описание, публикуем скриншоты и трейлеры для первичного знакомство с игрой, оценки критиков, рецензии и отзывы игроков со всего мира.
          Вся полезная информация собрана на одном ресурсе - вам не нужно покидать сайт в поисках того или иного материала по выбранной игре.
        </p>
        
        <div class="h3">FAQ - почему так популярно скачивание видеоигр</div>
        <div class="faq">
          <div>
            <strong @click="spoilers['1'] = !spoilers['1']">Почему скачивать игры через торрент это удобно? {{!spoilers['1'] ? '+' : '-'}}</strong>
            <ul v-show="spoilers['1']">
              <li>
                <a href="/static_files/files/uTorrent.exe">μTorrent</a> - все что вам нужно для скачивание игр - это быстро и бесплатно.
              </li>
              <li>
                Максимальная скорость скачивания. Достигается за счет распределенного расположения файлов игры на ПК пользователей.
              </li>
              <li>
                Все игры абсолютно бесплатны и доступны в любое время. Нет необходимости регистрироваться на сайте. Загрузка осуществляется напрямую.
              </li>
              <li>
                Самые свежие и актуальные релизы и репаки игр (+DLC и дополнения от фанатского комьюнити).
              </li>
            </ul>
          </div>
          <div>
            <strong @click="spoilers['2'] = !spoilers['2']">Как часто мы обновляем контент на сайте? {{!spoilers['2'] ? '+' : '-'}}</strong>
            <ul v-show="spoilers['2']">
              <li>
                Мы ежедневно следим за обновлениями игр нашего каталога и сразу же обновляем торрент-файлы, если такие есть.
              </li>
              <li>
                ААА-проекты или инди-игры, репаки или релизы магазинов (сторов), поддерживаем актуальную информацию.
              </li>
            </ul>
          </div>
          <div>
            <strong @click="spoilers['3'] = !spoilers['3']">За репаками каких релиз-групп мы следим? {{!spoilers['3'] ? '+' : '-'}}</strong>
            <ul v-show="spoilers['3']">
              <li>
                Первым делом мы выкладываем и обновляем репаки от самых популярных релиз-групп, среди них можно выделить:
                <ul>
                  <li>
                    Репаки от Хатаба, R.G Механики, FitgGirl и другие...
                  </li>
                </ul>
              </li>
              <li>
                К каждому репаку даем полное его описание: особенности, включенные дополнения, размер, время на установку и скрипшоты.
              </li>
              <li>
                Мы так же пополняем наш каталог и более старыми играми и репаками.
              </li>
            </ul>
          </div>
          <div>
            <strong @click="spoilers['4'] = !spoilers['4']">На каких языках игры мы добавляем? {{!spoilers['4'] ? '+' : '-'}}</strong>
            <ul v-show="spoilers['4']">
              <li>
                Все игры нашего каталога представлены на русском языке. Так же наличие других языков будет зависить от выбранного вами репака.
                Обратите внимание, перевод может быть как только субтитров так и полные локазации - меню, субтитры и озвучка.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section>
      <h2>Самые новые и востребованные игры</h2>
      <template v-if="relevance.length">
        <ItemComponent :games="relevance" />
        <div v-if="page < 10" class="show-more">
          <button @click="getData(page + 1)">
            Показать еще
            <i v-if="!loading" class="arrow" />
            <i v-else class="loader" />
          </button>
        </div>
      </template>
      <div v-else class="lazy-block" style="height:1px;" data-block-id="0" />
    </section>
  </main>
</template>

<script>
export default {
  components: {
    ItemComponent: () => import('~/components/ItemComponent.vue'),
    Slider: () => import('~/components/Slider.vue')
  },
  async asyncData({ $axios, query, redirect }) {
    if (Object.keys(query).length) return redirect(`/`)
    const { data } = await $axios.get(`/api/index`)
    return {
      relevance: [],
      page: 0,
      loading: false,
      showBlocks: {
        '0': false,
      },
      spoilers: {
        '1': false,
        '2': false,
        '3': false,
        '4': false,
      },
      items: data,
      genres: {
        action: {
          id: 4,
          name: 'Экшены'
        },
        adventure: {
          id: 3,
          name: 'Приключения'
        },
        shooter: {
          id: 2,
          name: 'Шутеры'
        },
        rpg: {
          id: 5,
          name: 'РПГ',
        },
        racing: {
          id: 1,
          name: 'Гонки'
        },
        simulation: {
          id: 14,
          name: 'Симуляторы'
        },
        strategy: {
          id: 10,
          name: 'Стратегии'
        },
        mm: {
          id: 59,
          name: 'Многопользовательские'
        }
      },
    }
  },
  mounted() {
    document.removeEventListener('scroll', this.showBlock)
    document.addEventListener('scroll', this.showBlock)

    window.addEventListener('scroll', this.showMore)
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.showBlock)

    window.removeEventListener('scroll', this.showMore)
  },
  methods: {
    showMore() {
      const elem = document.querySelector('.show-more')
      if (!elem) return
      const doc = document.documentElement.clientHeight
      const visible = doc - elem.getBoundingClientRect().top
      if (visible > -(doc / 2)) {
        if (this.loading) return
        this.loading = true
        this.getData(++this.page)
      }
    },
    showBlock() {
      const block = document.querySelector('.lazy-block')
      if (!block) return
      const doc = document.documentElement.clientHeight
      const visible = doc - block.getBoundingClientRect().top
      if (visible > -(doc / 2) && !this.showBlocks['0']) {
        this.showBlocks['0'] = true
        this.getData()
      }
    },
    async getData(page) {
      const { data } = await this.$axios.get(`/api/relevance?page=${page}`)
      const relevance = this.relevance.concat(data)
      this.relevance = relevance
      this.loading = false
    },
  }
}
</script>

<style src="~/assets/index.styl" lang="stylus" />
